.lead-content{
  font-size: 1.4rem;
  font-weight: 400;
  color:black;
}

.home-content{
  text-align: center;
  padding: 0 100px;

}

.cover-container {
  max-width: 80em;
  color:black;
}

.masthead-brand {
  color: #222E3D;
}

.mastfoot {
  color: #222E3D;
  position: absolute;
  bottom: 10px;
}
.mastfoot > div{
  text-align: center;
}

.masthead {
  margin-bottom: 2rem;
  color:white ;
}