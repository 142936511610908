#log-out-btn{
  background-color: #ffeb3b;
}

#log-out-btn:hover{
  background-color: #f9a825;
}

.user-title{
  color: #fff;
  margin-right: 10px;
}

.role-title{
  /*border-radius: 3px;*/
  padding: 0 3px;
  background-color: #bdbdbd;
  color: black;
  height: 100%;
}
