#read-grid{
  position: relative;
}
#read-nav-btn{
  position: absolute;
  top:-3px;
  z-index: 1;
  color: #1a237e;
}
#read-nav-icon{
  font-size: inherit;
}
.readLinkContent:hover{
  color: black;
}