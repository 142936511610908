body{
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",
	Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	font-size: 14px;
	font-weight: 400;
  line-height: 1.5;
}

.navbar-container{
	padding-top: 0;
	padding-bottom: 0;
	background-color: #222E3D;
}

.main-contain{
	background-color: #90caf9;
	min-height: 100vh;
}

.login-page{
	height: 100vh;
	display: flex;
	background: #3E5151; 
	background: -webkit-linear-gradient(to left, #DECBA4, #3E5151); 
	background: linear-gradient(to left, #DECBA4, #3E5151); 
}

.sub-contain{
	background: #fff;
	min-height: calc(100vh - 39px);
	position: relative;
}

.paging-content{
	position:absolute;
	bottom:0;
	width: 100%;
}

.navbar  a, .nav-item a {
	color: #fff !important;
}
/*/////////////////////////////////////////////////*/
#navbar-pages  .nav-link {
	padding: 4px 8px;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
}
#navbar-pages .nav-link:hover {
	background-color: #959681;
	color:black;
}
#navbar-pages .nav-item{
	padding: 5px;
}
#navbar-pages .nav-item > .active{
	background-color: white !important;
	color: #3B5352 !important;
}

#navbar-records{
	width: 13rem;
}
#navbar-records .nav-link:hover {
	color:black;
}
#navbar-records .active{
	background-color: #D9E0E5 !important;
	color:black;
	font-weight: bold;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.activeRecord{
	 background-color: #CBBE9D !important;
}
.activeRecord .btn-outline-warning{
	color: #212529 !important;
	background-color: #ffc107 !important;
	border-color: #ffc107 !important;
}
.text-validate{
	color: red;
	 font-size: 0.75em;
	 font-style: italic;
	 opacity: 0.7
}
.Spinner-submit{
	position: absolute;
	z-index: 0;
	top:0;
	bottom: 0;
	left: 0;
	right: 0;
	justify-content: center;
	align-items: center;
	background-color: aquamarine;
	width: 100%;
	height: 100%;
	opacity: 0.5;
}
.Spinner{
	opacity: 1;
}
.form-login{
	position: absolute;
	top:0
}

.inputContainer {
	position: relative;
}

.form_login{
	font-size: 20px;
	font-weight: bold;
}

.input_login{
	height: 40px !important;
	font-size: 18px !important;
	padding:1px 6px !important;
}

.login-form {
	margin: auto;
	margin-top: 60px;
}

.text-danger{
	font-size: 19px;
	font-weight: bold;
	color: #FFCD40 !important;
}

#input-addresses, #input-direction{
	resize: none;
	height: 56px;
}
#react-autowhatever-addresses{
	top:56px;
}
#react-autowhatever-personSharing {
	right:0;
	min-width: max-content;
}

#react-autowhatever-phoneNumber{
	 min-width: max-content;
 }

/*---Tooltip for react table---*/
.tooltipInfoRecord{
	text-align: left;
	line-height: 1;
	font-size: 15px;
	background-color: black !important;
}

/*---one row in react table---*/
.insert-box-content{
	padding: 0 5px !important;
}